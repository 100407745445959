import { doc, getDoc, } from "firebase/firestore";
import db from "../database";

export enum RegisterStatus {
  OPEN,
  CLOSED,
  DONE,
}

class Setting {
  static async getRegisterStatus(): Promise<RegisterStatus> {
    const docSnapshot = await getDoc(doc(db, "settings", "register"));

    const status = docSnapshot.exists() ? docSnapshot.data()!.value : "open"
    switch (status) {
      case "open":
        return RegisterStatus.OPEN;
      case "closed":
        return RegisterStatus.CLOSED;
      case "done":
        return RegisterStatus.DONE;
      default:
        return RegisterStatus.OPEN
    }
  }
}

export default Setting
import { Timestamp, doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import db from "../database";

class Customer {
  name: string;
  phone: string;
  email: string;
  created?: Timestamp;

  constructor(name: string, phone: string, email: string) {
    this.name = name;
    this.phone = phone;
    this.email = email;
  }

  _getObjectToSave() {
    return {
      name: this.name,
      phone: this.phone,
      email: this.email,
      created: serverTimestamp(),
    };
  }

  getHidedPhone(): string {
    return `${this.phone.slice(0, 2)}** *** ${this.phone.slice(7)}`;
  }

  getHidedEmail(): string {
    const designatorIndex = this.email.indexOf('@');
    return `${this.email.slice(0, 4)}********${designatorIndex >= 0 ? this.email.slice(designatorIndex) : ""}`;
  }

  async save() {
    try {
      const docSnapshot = await getDoc(doc(db, "customers", this.phone));
      if (docSnapshot.exists()) {
        throw new Error("duplicate");
      }

      await setDoc(
        doc(db, "customers", this.phone),
        this._getObjectToSave(),
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

export default Customer;

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyColc_wpS1R2Th4oQOE6m5H6irgWxsXjg0",
  authDomain: "boho-garden-test.firebaseapp.com",
  databaseURL:
    "https://boho-garden-test-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "boho-garden-test",
  storageBucket: "boho-garden-test.appspot.com",
  messagingSenderId: "956930799065",
  appId: "1:956930799065:web:bc6329add76add254c95c8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

export default db;

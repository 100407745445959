import { useEffect, useState } from "react";
import styles from "./App.module.css";
import RegisterScreen from "./screens/RegisterScreen/RegisterScreen";
import ConfirmScreen from "./screens/ConfirmScreen/ConfirmScreen";
import Setting, { RegisterStatus } from "./models/Setting";
import CloseScreen from "./screens/CloseScreen/CloseScreen";
import DoneScreen from "./screens/DoneScreen/DoneScreen";
import ResultScreen from "./screens/ResultScreen/ResultScreen";

const App = () => {
  const [registerDone, setRegisterDone] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [registerStatus, setRegisterStatus] = useState<RegisterStatus | undefined>(undefined);

  useEffect(() => {
    Setting.getRegisterStatus()
      .then((status) => setRegisterStatus(status))
      .catch((error) => console.log(error));
  }, []);

  const registerUI =
    registerDone ? (
      <ConfirmScreen />
    ) : (
      <RegisterScreen onSaveDone={() => setRegisterDone(true)} />
    )
  const closedUI = <CloseScreen />
  const doneUI =
    showResult ? (
      <ResultScreen />
    ) : (
      <DoneScreen onShowResult={() => setShowResult(true)} />
    )

  let mainUI = null;
  switch (registerStatus) {
    case RegisterStatus.OPEN:
      mainUI = registerUI;
      break;
    case RegisterStatus.CLOSED:
      mainUI = closedUI;
      break;
    case RegisterStatus.DONE:
      mainUI = doneUI;
      break;
  }

  return (
    <div className={styles.App}>
      <div className={styles.AppInner}>
        {mainUI}
      </div>
    </div>
  );
};

export default App;

import { useState } from "react";
import styles from "./RegisterScreen.module.css";
import backgroundImage from "../../images/1.jpg";
import registerButtonImage from "../../images/register-button.png";
import Customer from "../../models/Customer";
import Setting, { RegisterStatus } from "../../models/Setting";

type RegisterScreenProps = {
  onSaveDone: () => void,
}

const RegisterScreen = (props: RegisterScreenProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const validateHasError = () => {
    let hasError = false;
    if (name === "") {
      hasError = true;
      setNameError(true);
    }
    if (phone === "") {
      hasError = true;
      setPhoneError(true);
    }
    if (email === "") {
      hasError = true;
      setEmailError(true);
    }

    return hasError;
  }

  const register = async () => {
    if (validateHasError()) return;
    if (isSaving) return;

    try {
      setIsSaving(true);

      // check if the register is open or not
      const registerState = await Setting.getRegisterStatus();
      if (registerState !== RegisterStatus.OPEN) {
        window.location.reload();
        return;
      }

      const customer = new Customer(name, phone, email);
      await customer.save();
      props.onSaveDone();
    } catch (error: any) {
      if (error.message === 'duplicate') {
        setPhoneError(true);
        alert("Số điện thoại đã được đăng ký");
      }
    }
    finally {
      setIsSaving(false);
    }
  }

  const onSubmitForm: React.FormEventHandler = (event) => {
    event.preventDefault();
    register();
  };

  const onPressButton = () => {
    register();
  }

  return (
    <>
      <img
        className={styles.background}
        src={backgroundImage}
        alt="background"
      />
      <form className={styles.form} onSubmit={onSubmitForm}>
        <input
          className={nameError ? styles.errorField : ""}
          type="text"
          placeholder="HỌ VÀ TÊN :"
          maxLength={20}
          value={name}
          onChange={(event) => {
            setName(event.target.value.toUpperCase());
            setNameError(event.target.value === "");
          }}
        />
        <input
          className={emailError ? styles.errorField : ""}
          type="text"
          placeholder="EMAIL :"
          maxLength={50}
          value={email}
          onChange={(event) => {
            setEmail(event.target.value.toLowerCase());
            setEmailError(event.target.value === "");
          }}
        />
        <input
          className={phoneError ? styles.errorField : ""}
          type="text"
          placeholder="SỐ ĐIỆN THOẠI :"
          maxLength={10}
          value={phone}
          onChange={(event) => {
            setPhone(event.target.value);
            setPhoneError(event.target.value === "");
          }}
        />
        <img
          className={styles.registerButton}
          src={registerButtonImage}
          alt="register button"
          onClick={onPressButton}
        />
        <button type="submit" style={{ display: "none" }}></button>
      </form>
    </>
  );
};

export default RegisterScreen;


import { collection, getDocs, orderBy, query } from "firebase/firestore";
import Customer from "./Customer";
import db from "../database";

export enum Prize {
  PRIZE_1 = "prize_1",
  PRIZE_30 = "prize_30",
  PRIZE_50 = "prize_50",
}

const loadWinners = async (prize: Prize): Promise<Customer[]> => {
  try {
    const snapshotQuery = query(collection(db, prize), orderBy("created", "asc"));
    const snapshot = await getDocs(snapshotQuery);

    return snapshot.docs.map(doc => {
      const data = doc.data();
      return new Customer(data.name, data.phone, data.email);
    });
  } catch (error) {
    console.log(error);

    return [];
  }
}

const loadPrize1Winner = async (): Promise<Customer | undefined> => {
  const customers = await loadWinners(Prize.PRIZE_1);

  return customers.length ? customers[customers.length - 1] : undefined;
}

const loadPrize30Winner = async (): Promise<Customer[]> => {
  return await loadWinners(Prize.PRIZE_30);
}

const loadPrize50Winner = async (): Promise<Customer[]> => {
  return await loadWinners(Prize.PRIZE_50);
}

export { loadPrize1Winner, loadPrize30Winner, loadPrize50Winner, };
import image from "../../images/4.jpg"
import styles from "./DoneScreen.module.css"

type DoneScreenProps = {
  onShowResult: () => void,
}

const DoneScreen = (props: DoneScreenProps) => {
  return <>
    <button className={styles.button} onClick={props.onShowResult}></button>
    <img className={styles.background} src={image} alt="background" />
  </>
}

export default DoneScreen
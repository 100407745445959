import imageTop from "../../images/5-top.png"
import imageBottom from "../../images/5-bottom.png"
import styles from "./ResultScreen.module.css"
import { useEffect, useState } from "react"
import Customer from "../../models/Customer"
import { loadPrize1Winner, loadPrize30Winner, loadPrize50Winner } from "../../models/Prize"

type ResultProps = {
  title: string,
  results: Customer[]
}

const Result = ({ title, results }: ResultProps) => {
  return <div className={styles.resultTable}>
    <p>{title}</p>
    {
      !results.length ?
        <span>Không có kết quả</span>
        :
        <table>
          <tbody>
            {results.map((customer, index) =>
              <tr>
                <td>{index + 1}</td>
                <td align="left">{customer.name}</td>
                <td align="right">{customer.getHidedPhone()}</td>
              </tr>
            )}
          </tbody>
        </table>
    }
  </div>
}

const ResultScreen = () => {
  const [loading, setLoading] = useState(true);
  const [prize1Result, setPrize1Result] = useState<Customer[]>([]);
  const [prize30Result, setPrize30Result] = useState<Customer[]>([]);
  const [prize50Result, setPrize50Result] = useState<Customer[]>([]);

  useEffect(() => {
    Promise.all([
      loadPrize1Winner(),
      loadPrize30Winner(),
      loadPrize50Winner(),
    ])
      .then(([prize1, prize30, prize50]) => {
        setPrize1Result(prize1 ? [prize1] : []);
        setPrize30Result(prize30);
        setPrize50Result(prize50);
      })
      .catch((error) => alert("Lấy danh sách không thành công"))
      .finally(() => setLoading(false))
  }, [])

  const content =
    <>
      <img className={styles.background} src={imageTop} alt="header" />
      <Result title="01 iPhone 15 Pro Max" results={prize1Result} />
      <Result title="30 Cặp ly mascot" results={prize30Result} />
      <Result title="50 Vie - Chibi" results={prize50Result} />
      <img className={styles.background} src={imageBottom} alt="footer" />
    </>

  return loading ? <div className={styles.loading}><img src="/loading.gif" alt="loading" /></div> : content;
}

export default ResultScreen